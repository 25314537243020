import React from "react"
import {
  CheckerboardContainer,
  CheckerTextContainer,
  CheckerImg,
} from "./CheckerboardStyles"
import {
  ProjectSectionTitle,
  ProjectSectionParagraphs,
} from "../../../static/type/Typography"

const Checkerboard = props => {
  return (
    <CheckerboardContainer isLeft={props.isLeft}>
      <CheckerImg src={props.image.url} alt={props.image.alt} />
      <CheckerTextContainer>
        <ProjectSectionTitle>{props.title.text}</ProjectSectionTitle>
        <ProjectSectionParagraphs
          dangerouslySetInnerHTML={{ __html: props.paragraph.html }}
        />
      </CheckerTextContainer>
    </CheckerboardContainer>
  )
}

export default Checkerboard
