import React from "react"
import { ProjectHeroContainer, ProjectHeroImage } from "./HeroStyles"
import KeyInfo from "../KeyInfo/KeyInfo"

const ProjectHero = props => {
  return (
    <ProjectHeroContainer>
      <ProjectHeroImage src={props.heroImage.url} alt={props.heroImage.alt} />
      <KeyInfo
        keyInfo={props.keyInfo}
        title={props.projectTitle}
        subtitle={props.projectSubtitle}
      />
    </ProjectHeroContainer>
  )
}

export default ProjectHero
