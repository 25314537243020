import styled from "styled-components"
import { device } from "../../../static/sizes/Sizes"

export const ProjectHeroContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: top;
  margin-top: 40px;

  @media ${device.laptopS} {
    flex-direction: column;
    width: 60%;
    margin: auto;
  }

  @media ${device.tablet} {
    width: 90%;
  }
`

export const ProjectHeroImage = styled.img`
  width: 45%;
  margin: auto;
  @media ${device.laptopS} {
    width: 60%;
  }
  
  @media ${device.tablet} {
    width: 90%;
  }
`
