import styled from "styled-components"
import { ProjectSectionParagraphs } from "../../../static/type/Typography";
import { ProjectContainerSmall } from "../../../templates/ProjectStyles";

export const ProjectImageContainer = styled(ProjectContainerSmall)``;

export const ProjectImageImage = styled.img`
width: 100%;
`;

export const ProjectImageSubtitle = styled(ProjectSectionParagraphs)`
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-top: 20px;
`;