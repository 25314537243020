import React from "react"
import {
  TitleParagraphContainer,
} from "./TitleParagraphStyles"
import { ProjectSectionTitle, ProjectSectionParagraphs } from "../../../static/type/Typography"

const TitleParagraph = props => {
  return (
    <TitleParagraphContainer>
      <ProjectSectionTitle>{props.title.text}</ProjectSectionTitle>
      
      {/* I know...sory -Mel June 2020 */}
      <ProjectSectionParagraphs
        dangerouslySetInnerHTML={{ __html: props.paragraph.html }}
      />
    </TitleParagraphContainer>
  )
}

export default TitleParagraph
