import styled from "styled-components"
import { mch1, mch2, mcInternalLink } from "../static/type/Typography"
import { device } from "../static/sizes/Sizes"

export const ProjectPageContainer = styled.div`
  margin: 20px auto 40px;
  max-width: 1000px;
`

export const ProjectTitle = styled(mch1)`
  font-size: 48px;
  font-weight: 700;
  font-family: "PT Sans", sans-serif;
`

export const ProjectSubtitle = styled(mch2)`
  font-size: 16px;
  font-weight: 300;
`

export const ProjectContainerSmall = styled.div`
  width: 60%;
  margin: 60px auto 0;

  @media ${device.tablet} {
    margin: 30px auto 0;
    width: 90%;
  }
`

export const ProjectContainerLarge = styled.div`
  width: 100%;

  @media ${device.laptopS} {
    width: 60%;
  }

  @media ${device.tablet} {
    width: 90%;
  }
`

export const ProjectBackLink = styled(mcInternalLink)``