import styled from "styled-components"
import { ProjectContainerLarge } from "../../../templates/ProjectStyles"
import { device } from "../../../static/sizes/Sizes"

export const CheckerboardContainer = styled(ProjectContainerLarge)`
  display: flex;
  flex-direction: ${props => (props.isLeft ? "row" : "row-reverse")};
  justify-content: space-between;
  align-items: center;

  @media ${device.laptopS} {
    flex-direction: column;
    padding-bottom: 30px;
    margin: auto;
  }
`

export const CheckerTextContainer = styled.div`
  width: 45%;
  @media ${device.laptopS} {
    width: 100%;
  }
`

export const CheckerImg = styled.img`
  width: 50%;

  @media ${device.laptopS} {
    width: 100%;
  }
`
