import React from "react"
import { ProjectImageContainer, ProjectImageImage, ProjectImageSubtitle } from "./ProjectImageStyles"

const ProjectImage = props => {
  return (
    <ProjectImageContainer>
        <ProjectImageImage src={props.image.url} alt={props.image.alt}/>
        <ProjectImageSubtitle>
            {props.subtitle.text}
        </ProjectImageSubtitle>
    </ProjectImageContainer>
  )
}

export default ProjectImage
