import React from "react"
import {
  KeyInfoContainer,
  ProjectKeyInfoContainer,
  ProjectKeyInfoTitle,
  ProjectKeyInfoDescription,
  ProjectLiveLink,
} from "./KeyInfoStyles"
import { ProjectTitle, ProjectSubtitle } from "../../../templates/ProjectStyles"

const KeyInfo = props => {
  return (
    <KeyInfoContainer>
      <ProjectTitle>{props.title}</ProjectTitle>
      <ProjectSubtitle>{props.subtitle}</ProjectSubtitle>
      {props.keyInfo.map((element, index) => {
        return (
          <KeyInfoItem
            key={index}
            title={element.title}
            description={element.text}
            isLink={element.isLink}
          />
        )
      })}
    </KeyInfoContainer>
  )
}

const KeyInfoItem = props => {
  let component = {}
  !props.isLink
    ? (component = (
        <ProjectKeyInfoContainer>
          <ProjectKeyInfoTitle>{props.title}:&nbsp;</ProjectKeyInfoTitle>
          <ProjectKeyInfoDescription>
            {props.description}
          </ProjectKeyInfoDescription>
        </ProjectKeyInfoContainer>
      ))
    : (component = (
        <ProjectKeyInfoContainer>
          <ProjectKeyInfoTitle>{props.title}:&nbsp;</ProjectKeyInfoTitle>
          <ProjectLiveLink target="_blank" href={props.isLink}>
            {props.description}
          </ProjectLiveLink>
        </ProjectKeyInfoContainer>
      ))
  return component
}

export default KeyInfo
