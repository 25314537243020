import styled from "styled-components"
import { mcLink, mcp } from "../../../static/type/Typography"
import { device } from "../../../static/sizes/Sizes"

export const KeyInfoContainer = styled.div`
  width: 45%;
  @media ${device.laptopS} {
    width: 100%;
  }
`

export const ProjectKeyInfoContainer = styled.div`
`

export const ProjectKeyInfoTitle = styled(mcp)`
  display: inline-block;
  font-weight: 700;
`

export const ProjectKeyInfoDescription = styled(mcp)`
  display: inline-block;
`

export const ProjectLiveLink = styled(mcLink)`
  display: inline-block;
  font-size: 16px;
`
