const size = {
  mobile: "420px",
  tablet: "800px",
  laptopS: "1020px",
  laptopL: "1240px",
}

export const device = {
  mobile: `(max-width: ${size.mobile})`,
  tablet: `(max-width: ${size.tablet})`,
  laptopS: `(max-width: ${size.laptopS})`,
  laptopL: `(max-width: ${size.laptopL})`,
}
