import React from "react"
import { ProjectLinkContainer, ProjectLinkLink } from "./ProjectLinkStyles"
import { ProjectSectionTitle } from "../../../static/type/Typography"

const ProjectLink = props => {
  return (
    <ProjectLinkContainer>
      <ProjectSectionTitle>{props.title.text}</ProjectSectionTitle>
      <ProjectLinkLink href={props.link.url}>
        {props.context.text}
      </ProjectLinkLink>
    </ProjectLinkContainer>
  )
}

export default ProjectLink
