import React from "react"
import { graphql } from "gatsby"
import {
  ProjectPageContainer,
  ProjectBackLink,
  ProjectContainerSmall,
} from "./ProjectStyles"
import ProjectHero from "../components/Project/Hero/Hero"
import TitleParagraph from "../components/Project/TitleParagraph/TitleParagraph"
import Checkerboard from "../components/Project/Checkerboard/Checkerboard"
import ProjectImage from "../components/Project/ProjectImage/ProjectImage"
import ProjectLink from "../components/Project/ProjectLink/ProjectLink"
import { coverColor, linkDuration } from "../static/AnilinkConstants/AnilinkConstants"
import SEO from "../components/seo"
import "typeface-pt-sans";
import "typeface-roboto";

const Project = ({ data: { prismicProject } }) => {
  const { data } = prismicProject

  const keyInfo = [
    { title: "Role", text: data.project_role.text, isLink: false },
    { title: "Agency", text: data.agency.text, isLink: false },
    { title: "Software used", text: data.software_used.text, isLink: false },
    {
      title: "Live link",
      text: data.project_title.text,
      isLink: data.live_link.url,
    },
  ]

  return (
    <ProjectPageContainer>
      <SEO subtitle={data.project_title.text}/>
      <ProjectBackLink duration={linkDuration} to="/" cover bg={coverColor} direction="right">
        &lt; Back to all projects
      </ProjectBackLink>
      <ProjectHero
        heroImage={data.hero_image}
        keyInfo={keyInfo}
        projectTitle={data.project_title.text}
        projectSubtitle={data.project_subtitle.text}
      />
      {data.body.map((element, index) => {
        switch (element.__typename) {
          case "PrismicProjectBodyTitleParagraph":
            return (
              <TitleParagraph
                key={index}
                title={element.primary.tp_title}
                paragraph={element.primary.tp_paragraph}
              />
            )
          case "PrismicProjectBodyCheckerboardLeft":
            return (
              <Checkerboard
                key={index}
                isLeft={true}
                image={element.primary.checker_image}
                title={element.primary.checker_title}
                paragraph={element.primary.checker_paragraph}
              />
            )
          case "PrismicProjectBodyCheckerboardRight":
            return (
              <Checkerboard
                key={index}
                isLeft={false}
                image={element.primary.checker_image}
                title={element.primary.checker_title}
                paragraph={element.primary.checker_paragraph}
              />
            )
          case "PrismicProjectBodyImage":
            return (
              <ProjectImage
                key={index}
                image={element.primary.image_image}
                subtitle={element.primary.image_subtitle}
              />
            )
          case "PrismicProjectBodyTitleLink":
            return (
              <ProjectLink
                key={index}
                link={element.primary.tl_link}
                context={element.primary.tl_link_text}
                title={element.primary.tl_title}
              />
            )
          default:
            return null
        }
      })}
      <ProjectContainerSmall>
        <ProjectBackLink cover duration={linkDuration} bg={coverColor} direction="right" to="/">
          &lt; Back to all projects
        </ProjectBackLink>
      </ProjectContainerSmall>
    </ProjectPageContainer>
  )
}

export default Project

export const pageQuery = graphql`
  query ProjectBySlug($uid: String!) {
    prismicProject(uid: { eq: $uid }) {
      uid
      data {
        body {
          ... on PrismicProjectBodyTitleParagraph {
            id
            primary {
              tp_paragraph {
                html
              }
              tp_title {
                text
              }
            }
          }
          ... on PrismicProjectBodyImage {
            id
            primary {
              image_image {
                url
                alt
              }
              image_subtitle {
                text
              }
            }
          }
          ... on PrismicProjectBodyCheckerboardLeft {
            id
            primary {
              checker_image {
                alt
                url
              }
              checker_paragraph {
                html
              }
              checker_title {
                text
              }
            }
          }
          ... on PrismicProjectBodyTitleLink {
            id
            primary {
              tl_link {
                url
              }
              tl_link_text {
                text
              }
              tl_title {
                text
              }
            }
          }
          ... on PrismicProjectBodySectionSeparator {
            id
            primary {
              section_title {
                text
              }
            }
          }
          ... on PrismicProjectBodyCheckerboardRight {
            id
            primary {
              checker_image {
                alt
                url
              }
              checker_paragraph {
                html
              }
              checker_title {
                text
              }
            }
          }
        }
        hero_image {
          url
          alt
        }
        project_role {
          text
        }
        live_link {
          url
        }
        project_subtitle {
          text
        }
        project_title {
          text
        }
        software_used {
          text
        }
        agency {
          text
        }
        alert_text {
          html
        }
      }
    }
  }
`
